export const COUNTDOWN_CLOCK_STATE = 'CountdownClock';

export const END_MESSAGE_STATE = 'EndMessage';

export const enum CurrentState {
  Clock = 'clock',
  End_Message = 'end_message',
}

export const enum PresetLayout {
  StripLayout = 'Strip Layout',
  MobileStrip = 'Mobile strip',
  BoxLayout = 'Box Layout',
  MobileBox = 'Mobile box',
}
