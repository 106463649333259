import { WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export const timerProps = {
  daysLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'Days',
  },
  hoursLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'Hours',
  },
  minutesLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'Minutes',
  },
  secondsLabel: {
    type: WidgetPropertyType.STRING,
    defaultValue: 'Seconds',
  },
  showLabels: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  showSeparators: {
    type: WidgetPropertyType.BOOLEAN,
    defaultValue: true,
  },
  animationType: {
    type: WidgetPropertyType.STRING,
    defaultValue: '',
  },
};
