import moment, { Moment } from 'moment-timezone';
import { countdownProps } from './countdownProps';
import { countdownClockAppPublishedNewEvent } from '@wix/bi-logger-wixlabs-users/v2';
import webBiLogger from '@wix/web-bi-logger';
import { timerProps } from '../Timer1/timerProps';
import { desktopPresets, mobilePresets } from '../../assets/presets/presetsIds';
import { PresetValue } from '@wix/editor-platform-sdk-types/dist/types/sdk/document/application';
import { DeviceTypeValue, SendBiI } from '../Types';
import { DeviceType } from '@wix/platform-editor-sdk';

export const getSelectedTimezone = (timeZone) => {
  if (timeZone === '') {
    return moment.tz.guess();
  }
  return timeZone;
};
export const getEndDate = (props, getLoggedInDateByID) => {
  const {
    timeZone,
    no_repeat_endDateTimer,
    no_repeat_endTime,
    countDownType,
    per_visitor_amount_of_time,
    per_visitor_time_Unit,
  } = props;
  const selectedTimeZone = getSelectedTimezone(timeZone);
  let countdownEndDate: Moment | undefined;
  let remainingTimeToStart = 0;
  switch (countDownType) {
    case 'no_repeat':
      countdownEndDate = handleNoRepeat(
        no_repeat_endDateTimer,
        no_repeat_endTime,
        selectedTimeZone,
      );
      break;

    case 'repeat':
      const res = handleRepeat(props, selectedTimeZone);
      countdownEndDate = res.countdownEndDate;
      remainingTimeToStart = res.remainingTimeToStart;
      break;

    case 'repeat_per_visitor':
      const perVisitorData = handleRepeatPerVisitor(
        per_visitor_amount_of_time,
        per_visitor_time_Unit,
        selectedTimeZone,
        getLoggedInDateByID,
      );
      countdownEndDate = perVisitorData.countdownEndDate;
      remainingTimeToStart = perVisitorData.remainingTimeToStart;
      break;
  }

  return { countdownEndDate, remainingTimeToStart };
};

const handleNoRepeat = (
  no_repeat_endDateTimer,
  no_repeat_endTime,
  selectedTimeZone,
) => {
  const endDate = moment();
  endDate.tz(selectedTimeZone);
  const [hours, minutes] = no_repeat_endTime.split(':');
  endDate.set(moment(no_repeat_endDateTimer).toObject());
  endDate.set({
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
    seconds: 0,
  });
  return endDate;
};
const isDayValidInCurrentMonth = (date: Moment, day: number) => {
  const daysInCurrentMonth = date.daysInMonth();
  if (day >= daysInCurrentMonth) {
    return date.endOf('month').date();
  } else {
    return day;
  }
};
const handleRepeat = (props, selectedTimeZone) => {
  const { repeat_type } = props;
  let countdownEndDate: Moment | undefined;
  let remainingTimeToStart = 0;
  const startDate = moment();
  const endDate = moment();
  if (repeat_type === 'daily_countdown') {
    const { daily_endTime, daily_startTime } = props;
    const [endHour, endMinutes] = daily_endTime.split(':');
    const [startHour, startMinutes] = daily_startTime.split(':');
    startDate.tz(selectedTimeZone).set({
      hours: parseInt(startHour, 10),
      minutes: parseInt(startMinutes, 10),
      seconds: 0,
    });
    endDate.tz(selectedTimeZone).set({
      hours: parseInt(endHour, 10),
      minutes: parseInt(endMinutes, 10),
      seconds: 0,
    });
  } else if (repeat_type === 'weekly_countdown') {
    const { weekly_startDay, weekly_endDay, weekly_startTime, weekly_endTime } =
      props;
    const [startHour, startMinutes] = weekly_startTime.split(':');
    const [endHour, endMinutes] = weekly_endTime.split(':');
    startDate.tz(selectedTimeZone).set({
      day: weekly_startDay,
      hours: parseInt(startHour, 10),
      minutes: parseInt(startMinutes, 10),
      seconds: 0,
    });
    endDate.tz(selectedTimeZone).set({
      day: weekly_endDay,
      hours: parseInt(endHour, 10),
      minutes: parseInt(endMinutes, 10),
      seconds: 0,
    });
  } else if (repeat_type === 'monthly_countdown') {
    const {
      monthly_startTime,
      monthly_endTime,
      monthly_startDay,
      monthly_endDay,
    } = props;
    const [startHour, startMinutes] = monthly_startTime.split(':');
    const [endHour, endMinutes] = monthly_endTime.split(':');
    startDate.tz(selectedTimeZone).set({
      hours: parseInt(startHour, 10),
      minutes: parseInt(startMinutes, 10),
      seconds: 0,
      date: isDayValidInCurrentMonth(
        moment().tz(selectedTimeZone),
        monthly_startDay,
      ),
    });
    endDate.tz(selectedTimeZone).set({
      hours: parseInt(endHour, 10),
      minutes: parseInt(endMinutes, 10),
      seconds: 0,
      date: isDayValidInCurrentMonth(
        moment().tz(selectedTimeZone),
        monthly_endDay,
      ),
    });
  }
  if (startDate.isBefore(endDate)) {
    remainingTimeToStart = startDate.diff(moment(), 'milliseconds', true);
    countdownEndDate = endDate;
  } else {
    countdownEndDate = moment();
    remainingTimeToStart = -1;
  }
  return { countdownEndDate, remainingTimeToStart };
};
const handleRepeatPerVisitor = (
  per_visitor_amount_of_time: number,
  per_visitor_time_Unit: any,
  selectedTimeZone: string,
  getLoggedInDateByID,
) => {
  let countdownEndDate: Moment | undefined;
  let remainingTimeToStart;
  const loggedInDate = getLoggedInDateByID();
  if (loggedInDate) {
    const startDate = moment(loggedInDate).tz(selectedTimeZone);
    const endDate = moment(loggedInDate)
      .tz(selectedTimeZone)
      .add(per_visitor_amount_of_time, per_visitor_time_Unit);
    if (startDate.isBefore(endDate)) {
      remainingTimeToStart = startDate.diff(moment(), 'milliseconds', true);
      countdownEndDate = endDate;
    } else {
      countdownEndDate = moment();
      remainingTimeToStart = -1;
    }
  } else {
    countdownEndDate = moment();
    remainingTimeToStart = -1;
  }
  return { countdownEndDate, remainingTimeToStart };
};
const getSelectedPresetName = ({
  timerSelectedPreset,
  deviceType,
}: {
  timerSelectedPreset: PresetValue;
  deviceType: DeviceTypeValue;
}) => {
  const presets =
    deviceType === DeviceType.Desktop ? desktopPresets : mobilePresets;
  return timerSelectedPreset
    ? presets.filter(({ id }) => id === timerSelectedPreset.layout)[0].name
    : presets[0].name;
};
export const sendBi = async ({
  biEventsParams = {
    timerSelectedPreset: {
      style: undefined,
      layout: undefined,
    },
    deviceType: DeviceType.Desktop,
  },
  editorSDK,
}: SendBiI) => {
  const params: any = {};
  const {
    countDownType,
    no_repeat_endDateTimer,
    no_repeat_endTime,
    timeZone,
    repeat_type,
    daily_endTime,
    daily_startTime,
    weekly_startTime,
    weekly_endTime,
    monthly_startTime,
    monthly_endTime,
    per_visitor_time_Unit,
    per_visitor_amount_of_time,
    showEndMessage,
    selectedPreset,
    daysShowInElementsPanel_countdown,
    hoursShowInElementsPanel_countdown,
    minutesShowInElementsPanel_countdown,
    secondsShowInElementsPanel_countdown,
    countdownTitleShowInElementsPanel_countdown,
    countdownButtonShowInElementsPanel_countdown,
    endMessageTitleShowInElementsPanel_countdown,
    endMessageButtonShowInElementsPanel_countdown,
    animationType,
    showSeparators,
    daysLabel,
    hoursLabel,
    minutesLabel,
    secondsLabel,
    showLabels,
    timerSelectedPreset,
    deviceType,
  } = biEventsParams;
  const logger = webBiLogger.factory().logger();
  const instanceId = await editorSDK.info.getAppInstanceId('');
  const siteId = await editorSDK.info.getSiteId('');
  const metaSiteId = await editorSDK.info.getMetaSiteId('');
  const uuid = await editorSDK.info.getUserId('');
  params.clock_type =
    countDownType !== undefined
      ? countDownType
      : countdownProps.countDownType.defaultValue;
  if (params.clock_type === 'no_repeat') {
    params.settings_details = JSON.stringify({
      end_date:
        no_repeat_endDateTimer !== undefined
          ? no_repeat_endDateTimer
          : countdownProps.no_repeat_endDateTimer.defaultValue,
      end_time:
        no_repeat_endTime !== undefined
          ? no_repeat_endTime
          : countdownProps.no_repeat_endTime.defaultValue,
      time_zone:
        timeZone !== undefined
          ? timeZone
          : countdownProps.timeZone.defaultValue,
    });
  } else if (params.clock_type === 'repeat') {
    const repeatType =
      repeat_type !== undefined
        ? repeat_type
        : countdownProps.repeat_type.defaultValue;
    const settings_details =
      repeatType === 'daily_countdown'
        ? {
            time_unit: 'daily',
            time_start:
              daily_startTime !== undefined
                ? daily_startTime
                : countdownProps.daily_startTime.defaultValue,
            end_time:
              daily_endTime !== undefined
                ? daily_endTime
                : countdownProps.daily_endTime.defaultValue,
            time_zone:
              timeZone !== undefined
                ? timeZone
                : countdownProps.timeZone.defaultValue,
          }
        : repeatType === 'weekly_countdown'
        ? {
            time_unit: 'weekly',
            time_start:
              weekly_startTime !== undefined
                ? weekly_startTime
                : countdownProps.weekly_startTime.defaultValue,
            end_time:
              weekly_endTime !== undefined
                ? weekly_endTime
                : countdownProps.weekly_endTime.defaultValue,
            time_zone:
              timeZone !== undefined
                ? timeZone
                : countdownProps.timeZone.defaultValue,
          }
        : {
            time_unit: 'monthly',
            time_start:
              monthly_startTime !== undefined
                ? monthly_startTime
                : countdownProps.monthly_startTime.defaultValue,
            end_time:
              monthly_endTime !== undefined
                ? monthly_endTime
                : countdownProps.monthly_endTime.defaultValue,
            time_zone:
              timeZone !== undefined
                ? timeZone
                : countdownProps.timeZone.defaultValue,
          };
    params.settings_details = JSON.stringify(settings_details);
  } else if (params.clock_type === 'repeat_per_visitor') {
    params.settings_details = JSON.stringify({
      time_unit:
        per_visitor_time_Unit !== undefined
          ? per_visitor_time_Unit
          : countdownProps.per_visitor_time_Unit.defaultValue,
      value:
        per_visitor_amount_of_time !== undefined
          ? per_visitor_amount_of_time
          : countdownProps.per_visitor_amount_of_time.defaultValue,
    });
  }
  params.show_message =
    showEndMessage !== undefined
      ? showEndMessage
      : countdownProps.showEndMessage.defaultValue;
  params.layout = selectedPreset !== undefined ? selectedPreset : 'Box';
  params.widget_elements = JSON.stringify({
    clock_title_text:
      countdownTitleShowInElementsPanel_countdown !== undefined
        ? countdownTitleShowInElementsPanel_countdown
        : countdownProps.countdownTitleShowInElementsPanel_countdown
            .defaultValue,
    clock_button:
      countdownButtonShowInElementsPanel_countdown !== undefined
        ? countdownButtonShowInElementsPanel_countdown
        : countdownProps.countdownButtonShowInElementsPanel_countdown
            .defaultValue,
    message_title_text:
      endMessageTitleShowInElementsPanel_countdown !== undefined
        ? endMessageTitleShowInElementsPanel_countdown
        : countdownProps.endMessageTitleShowInElementsPanel_countdown
            .defaultValue,
    message_button:
      endMessageButtonShowInElementsPanel_countdown !== undefined
        ? endMessageButtonShowInElementsPanel_countdown
        : countdownProps.endMessageButtonShowInElementsPanel_countdown
            .defaultValue,
    days:
      daysShowInElementsPanel_countdown !== undefined
        ? daysShowInElementsPanel_countdown
        : countdownProps.daysShowInElementsPanel_countdown.defaultValue,
    hours:
      hoursShowInElementsPanel_countdown !== undefined
        ? hoursShowInElementsPanel_countdown
        : countdownProps.hoursShowInElementsPanel_countdown.defaultValue,
    minutes:
      minutesShowInElementsPanel_countdown !== undefined
        ? minutesShowInElementsPanel_countdown
        : countdownProps.minutesShowInElementsPanel_countdown.defaultValue,
    seconds:
      secondsShowInElementsPanel_countdown !== undefined
        ? secondsShowInElementsPanel_countdown
        : countdownProps.secondsShowInElementsPanel_countdown.defaultValue,
  });
  params.digit_animation =
    animationType !== undefined
      ? animationType
      : timerProps.animationType.defaultValue;
  params.show_digit_separator =
    showSeparators !== undefined
      ? showSeparators
      : timerProps.showSeparators.defaultValue;
  params.unit_label_text = JSON.stringify({
    days_text:
      daysLabel !== undefined ? daysLabel : timerProps.daysLabel.defaultValue,
    hours_text:
      hoursLabel !== undefined
        ? hoursLabel
        : timerProps.hoursLabel.defaultValue,
    minutes_text:
      minutesLabel !== undefined
        ? minutesLabel
        : timerProps.minutesLabel.defaultValue,
    seconds_text:
      secondsLabel !== undefined
        ? secondsLabel
        : timerProps.secondsLabel.defaultValue,
  });
  params.show_unit_labels =
    showLabels !== undefined ? showLabels : timerProps.showLabels.defaultValue;
  params.present_name = getSelectedPresetName({
    timerSelectedPreset,
    deviceType,
  });
  await logger.report(
    countdownClockAppPublishedNewEvent({
      app_site_id: siteId,
      instance_id: instanceId,
      biToken: metaSiteId,
      uuid,
      ...params,
    }),
  );
};
